// Too difficult to type properly.
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  camelCase,
  isArray,
  isPlainObject,
  isString,
  snakeCase,
  upperFirst as _upperFirst,
} from 'lodash';
import * as R from 'remeda';

// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case
export type SnakeToCamel<S extends string> =
  S extends `${infer T}_${infer U}_${infer V}`
    ? `${T}${Capitalize<U>}${Capitalize<SnakeToCamel<V>>}`
    : S extends `${infer T}_${infer U}`
      ? `${T}${Capitalize<SnakeToCamel<U>>}`
      : S;

export type CamelToSnake<S extends string> =
  S extends `${infer T}${infer U}${infer V}`
    ? T extends '_'
      ? `_${CamelToSnake<`${U}${V}`>}`
      : `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnake<`${U}${V}`>}`
    : S extends `${infer T}${infer U}`
      ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnake<U>}`
      : S;

export const snakeToCamel = <T extends string>(s: T): SnakeToCamel<T> =>
  camelCase(s) as SnakeToCamel<T>;

export const camelToSnake = <T extends string>(s: T): CamelToSnake<T> =>
  snakeCase(s) as CamelToSnake<T>;

type SnakeToCamelObject<T> = T extends object
  ? {
      [K in keyof T as SnakeToCamel<K & string>]: SnakeToCamelObject<T[K]>;
    }
  : T;

type CamelToSnakeObject<T> = T extends object
  ? { [K in keyof T as CamelToSnake<K & string>]: CamelToSnakeObject<T[K]> }
  : T;

const caseArrayOrObject = (
  target: any,
  {
    asObject,
    asArray,
    asString = x => x,
  }: {
    asObject: (_obj: Record<string, any>) => object;
    asArray: (_arr: Array<any>) => Array<any>;
    asString?: (_str: string) => string;
  }
) =>
  isPlainObject(target)
    ? asObject(target)
    : isArray(target)
      ? asArray(target)
      : isString(target)
        ? asString(target)
        : target;

// possible overloads, last one is just for backwards compatibility
type KeyConvertor = {
  <InputType>(
    fn: <T extends string>(_key: T) => SnakeToCamel<T>,
    excludeParentKeys?: string[]
  ): (target: InputType) => SnakeToCamelObject<InputType>;
  <InputType>(
    fn: <T extends string>(_key: T) => CamelToSnake<T>,
    excludeParentKeys?: string[]
  ): (target: InputType) => CamelToSnakeObject<InputType>;
  <InputType>(
    fn: (_key: string) => string,
    excludeParentKeys?: string[]
  ): (target: InputType) => any;
};

export const convertKeys: KeyConvertor =
  (fn: any, excludeParentKeys: string[] = []) =>
  (target: any): any =>
    caseArrayOrObject(target, {
      asObject: object =>
        Object.keys(object).reduce(
          (acc, key) => ({
            ...acc,
            [fn(key)]: excludeParentKeys.includes(key)
              ? object[key]
              : convertKeys(fn, excludeParentKeys)(object[key]),
          }),
          {}
        ),
      asArray: array => array.map(convertKeys(fn, excludeParentKeys)),
    });

type ConvertValuesToSnakeCase<T, E> = T extends string
  ? CamelToSnake<T>
  : T extends Array<infer U>
    ? Array<ConvertValuesToSnakeCase<U, E>>
    : T extends Record<string, unknown>
      ? {
          [K in keyof T]: K extends E
            ? T[K]
            : ConvertValuesToSnakeCase<T[K], E>;
        }
      : T;

export const convertStringValuesToSnake =
  <E extends string>(excludedKeys: Array<E>) =>
  <T>(target: T): ConvertValuesToSnakeCase<T, E> => {
    if (R.isPlainObject(target)) {
      return R.mapValues(target, (value, key) => {
        return (
          R.isIncludedIn(String(key), excludedKeys)
            ? value
            : convertStringValuesToSnake(excludedKeys)(value)
        ) as ConvertValuesToSnakeCase<T, E>;
      }) as ConvertValuesToSnakeCase<T, E>;
    }

    if (R.isArray(target)) {
      return target.map(
        convertStringValuesToSnake(excludedKeys)
      ) as ConvertValuesToSnakeCase<T, E>;
    }

    if (R.isString(target)) {
      return camelToSnake(target) as ConvertValuesToSnakeCase<T, E>;
    }

    return target as ConvertValuesToSnakeCase<T, E>;
  };

type SnakeToSpaces<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T} ${SnakeToSpaces<U>}`
  : S;

export const snakeToSpaces = <S extends string>(type: S): SnakeToSpaces<S> =>
  type.replaceAll('_', ' ') as SnakeToSpaces<S>;

export const upperFirst = <S extends string>(s: S): Capitalize<S> =>
  _upperFirst(s) as Capitalize<S>;

// https://stackoverflow.com/a/70831818
export type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ''
    ? []
    : S extends `${infer T}${D}${infer U}`
      ? [T, ...Split<U, D>]
      : [S];

export default convertKeys;
